import { useEffect, useState } from 'react';
import { useAccount, useSetClientId } from '../hooks/useAccount';

export default function GA4UserProperties() {
	const { monthlyPrice, currentlyPaying, gaClientId, shop, billedSubscribers } = useAccount();
	const [foundClientId, setFoundClientId] = useState<string>();

	const { setClientId } = useSetClientId();

	useEffect(() => {
		if (!window.gtag) {
			return;
		}
		window.gtag('config', 'G-9JVDCZ5PXF', {
			user_id: shop
		});
		window.gtag('set', 'user_properties', {
			activeSubscribers: billedSubscribers,
			paidUserValue: currentlyPaying ? monthlyPrice : 0
		});
	}, [billedSubscribers, currentlyPaying, monthlyPrice, shop]);

	useEffect(() => {
		if (!window.gtag) {
			return;
		}
		window.gtag('get', 'G-9JVDCZ5PXF', 'client_id', (clientId: string) => setFoundClientId(clientId));
	}, []);

	useEffect(() => {
		if (foundClientId && gaClientId !== foundClientId) {
			setClientId(foundClientId);
		}
	}, [gaClientId, foundClientId, setClientId]);

	return null;
}
