import { useAccount } from '../hooks/useAccount';

export const FeatureFlags = Object.freeze({
	LINK_NAMING_ENABLED: import.meta.env.VITE_LINK_NAMING_ENABLED === '1',
	MODIFY_TAGS_NODE_ENABLED: import.meta.env.VITE_MODIFY_TAGS_NODE_ENABLED === '1',
	INTERCOM_ENABLED: import.meta.env.VITE_INTERCOM_ENABLED === '1',
	INTERCOM_MESSENGER_ENABLED: import.meta.env.VITE_INTERCOM_ENABLED === '1' && import.meta.env.VITE_INTERCOM_MESSENGER_ENABLED === '1',
	COLLAPSIBLE_AUTOMATION_LIST_ENABLED: import.meta.env.VITE_COLLAPSIBLE_AUTOMATION_LIST_ENABLED === '1',
	STANDARD_UPGRADE_BILLING_ENABLED: import.meta.env.VITE_STANDARD_UPGRADE_BILLING_ENABLED === '1',
	WELCOME_SURVEY_ENABLED: import.meta.env.VITE_WELCOME_SURVEY_ENABLED === '1',
	KLAVIYO_SYNC_ENABLED: import.meta.env.VITE_KLAVIYO_SYNC_ENABLED === '1',
	CANVA_CONNECT_ENABLED: import.meta.env.VITE_CANVA_CONNECT_ENABLED === '1',
	EDITOR_CONTEXTUAL_SAVE_BAR_ENABLED: import.meta.env.VITE_EDITOR_CONTEXTUAL_SAVE_BAR_ENABLED === '1',
	KLAVIYO_SYNC_ACCOUNTS: import.meta.env.VITE_KLAVIYO_SYNC_ACCOUNTS
});

export function useKlaviyoSyncEnabled() {
	const { id } = useAccount();
	return FeatureFlags.KLAVIYO_SYNC_ENABLED || FeatureFlags.KLAVIYO_SYNC_ACCOUNTS?.split(',').includes(id.toString());
}
