import { isNotBlank, ScrollToTop, WebVitalsWidget } from '@segunosoftware/equinox';
import '@segunosoftware/equinox/dist/styles.css';
import * as Sentry from '@sentry/react';
import '@shopify/polaris/build/esm/styles.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import APIProvider from './APIProvider';
import App from './App';
import EmbeddedMarketingProvider from './EmbeddedMarketingProvider';
import { createQueryClient } from './lib/ReactQueryConfig';
import InvalidationRegistry from './utils/InvalidationRegistry';

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
	Sentry.init({
		release: isNotBlank(__SEGUNO_APP_VERSION__) ? `platform-frontend@${__SEGUNO_APP_VERSION__}` : undefined,
		dsn: 'https://8b136ebf7ea04cff833322f5a6b6324c@o155661.ingest.sentry.io/1219243',
		integrations: [new Sentry.BrowserTracing()],
		tracesSampleRate: 1,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT
	});
}
const apiEndpoint = import.meta.env.VITE_API_ENDPOINT;

const queryClient = createQueryClient();
InvalidationRegistry.init(queryClient);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<StrictMode>
		<APIProvider baseUrl={apiEndpoint}>
			<EmbeddedMarketingProvider>
				<QueryClientProvider client={queryClient}>
					<Router>
						<ScrollToTop>
							<App />
						</ScrollToTop>
						<WebVitalsWidget enabled={import.meta.env.VITE_WEB_VITALS_WIDGET_ENABLED === '1'} />
					</Router>
					<ReactQueryDevtools />
				</QueryClientProvider>
			</EmbeddedMarketingProvider>
		</APIProvider>
		{/* {import.meta.env.VITE_SENTRY_ENVIRONMENT === 'production' && <WebVitalsSubmitter />} */}
	</StrictMode>
);
