import { asDefault as ad, lazyWithReload as lazy } from '@segunosoftware/equinox';

export const OAuth = lazy(() => import('../components/OAuth'));

export const CreateCampaign = lazy(() => import('../components/campaigns/CreateCampaign'));
export const CampaignDetails = lazy(() => import('../components/campaigns/CampaignDetails'));
export const CampaignRemail = lazy(() => import('../components/campaigns/CampaignRemail').then(m => ad(m.CampaignRemail)));
export const CampaignEditor = lazy(() => import('../components/campaigns/CampaignEditor'));
export const CampaignActivities = lazy(() => import('../components/campaigns/CampaignActivities'));
export const CampaignList = lazy(() => import('../components/campaigns/CampaignList'));
export const CampaignGroupList = lazy(() => import('../components/campaigngroups/CampaignGroupList'));
export const CampaignGroupDetails = lazy(() => import('../components/campaigngroups/CampaignGroupDetails'));
export const CreateCampaignGroup = lazy(() => import('../components/campaigngroups/CreateCampaignGroup'));

export const DuplicateCampaignModal = lazy(() =>
	import('../components/campaigns/DuplicateCampaignModal').then(m => ad(m.DuplicateCampaignModal))
);

export const AutomationsList = lazy(() => import('../components/automations/AutomationsList'));
export const AutomationTriggerFlowActionEditor = lazy(() => import('../components/AutomationTriggerFlowActionEditor'));
export const ProgramDetails = lazy(() => import('../components/programs/ProgramDetails'));
export const ProgramEditor = lazy(() => import('../components/programs/ProgramEditor'));
export const ProgramActivities = lazy(() => import('../components/programs/ProgramActivities'));
export const ProgramMessageActivities = lazy(() => import('../components/programs/ProgramMessageActivities'));
export const CreateFlowAction = lazy(() => import('../components/programs/CreateProgram').then(m => ad(m.CreateFlowAction)));
export const CreateProgramOfType = lazy(() => import('../components/programs/CreateProgram').then(m => ad(m.CreateProgramOfType)));
export const CreateMarketingAutomation = lazy(() =>
	import('../components/programs/CreateProgram').then(m => ad(m.CreateMarketingAutomation))
);
export const PostPurchaseProgramSettings = lazy(() => import('../components/programs/PostPurchaseProgramSettings'));
export const TagTriggeredProgramSettings = lazy(() => import('../components/programs/TagTriggeredProgramSettings'));
export const DiscountReminderProgramSettings = lazy(() => import('../components/programs/DiscountReminderProgramSettings'));
export const BackInStockRegistrants = lazy(() => import('../components/automations/BackInStockRegistrants'));

export const CustomerTagPicker = lazy(() => import('../components/CustomerTagPicker'));
export const ProductCollectionRender = lazy(() => import('../components/ProductCollectionRender'));
export const ProductRender = lazy(() => import('../components/ProductRender'));

export const CreateEmailTemplate = lazy(() => import('../components/templates/CreateEmailTemplate'));

export const HTMLSection = lazy(() => import('../components/editor/sections/HTMLSection'));

export const FullscreenPage = lazy(() => import('../components/FullscreenPage'));
export const MarketingExternalActivityTopBar = lazy(() => import('../components/MarketingExternalActivityTopBar'));

export const CopyToProgramModal = lazy(() => import('../components/CopyToProgramModal'));
export const DiscountSettings = lazy(() => import('../components/DiscountSettings'));

export const MobileWistiaVideoCard = lazy(() => import('../components/MobileWistiaVideoCard'));

export const KlaviyoConnect = lazy(() => import('../components/klaviyo/KlaviyoConnect'));

export const MailchimpConnect = lazy(() => import('../components/mailchimp/MailchimpConnect'));
export const MailchimpCallback = lazy(() => import('../components/mailchimp/MailchimpCallback'));

export const CanvaCallback = lazy(() => import('../components/CanvaCallback'));

export const Settings = lazy(() => import('../components/settings/Settings'));
export const MailchimpFinishConnection = lazy(() => import('../components/settings/MailchimpFinishConnection'));

export const TranslationSettings = lazy(() => import('../components/translations/TranslationSettings'));

export const ConnectionDetails = lazy(() => import('../components/connections/ConnectionDetails'));
export const ConnectionsOverview = lazy(() => import('../components/connections/ConnectionsOverview'));
export const AbraConnection = lazy(() => import('../components/connections/AbraConnection'));
export const CanvaConnection = lazy(() => import('../components/connections/CanvaConnection'));
export const DynamicBannerSuiteConnection = lazy(() => import('../components/connections/DynamicBannerSuiteConnection'));
export const FacebookConnection = lazy(() => import('../components/connections/FacebookConnection'));
export const FeraProductReviewsConnection = lazy(() => import('../components/connections/FeraProductReviewsConnection'));
export const GemPagesConnection = lazy(() => import('../components/connections/GemPagesConnection'));
export const InstagramConnection = lazy(() => import('../components/connections/InstagramConnection'));
export const JudgeMeProductReviewsConnection = lazy(() => import('../components/connections/JudgeMeProductReviewsConnection'));
export const JunipProductReviewsConnection = lazy(() => import('../components/connections/JunipProductReviewsConnection'));
export const KlaviyoConnection = lazy(() => import('../components/connections/KlaviyoConnection'));
export const LooxProductReviewsConnection = lazy(() => import('../components/connections/LooxProductReviewsConnection'));
export const MailchimpConnection = lazy(() => import('../components/connections/MailchimpConnection'));
export const PageFlyConnection = lazy(() => import('../components/connections/PageFlyConnection'));
export const PostscriptConnection = lazy(() => import('../components/connections/PostscriptConnection'));
export const SegunoPopupsConnection = lazy(() => import('../components/connections/SegunoPopupsConnection'));
export const ShopifyProductReviewsConnection = lazy(() => import('../components/connections/ShopifyProductReviewsConnection'));
export const StampedProductReviewsConnection = lazy(() => import('../components/connections/StampedProductReviewsConnection'));
export const YotpoProductReviewsConnection = lazy(() => import('../components/connections/YotpoProductReviewsConnection'));

export const CustomerSearch = lazy(() => import('../components/customers/CustomerSearch'));
export const CustomerDetails = lazy(() => import('../components/customers/CustomerDetails'));

export const SubscribersReport = lazy(() => import('../components/reports/SubscribersReport'));
export const EngagementReport = lazy(() => import('../components/reports/engagement/EngagementReport'));

export const SurveyModal = lazy(() => import('../components/SurveyModal'));

export const StoreCampaignGroupDetails = lazy(() => import('../components/store/StoreCampaignGroupDetails'));
export const TemplateStore = lazy(() => import('../components/store/TemplateStore'));
export const ArchivedTemplateList = lazy(() => import('../components/templates/ArchivedTemplateList'));
export const CampaignTemplateEditor = lazy(() => import('../components/templates/CampaignTemplateEditor'));
export const TemplateList = lazy(() => import('../components/templates/TemplateList'));
export const FeaturedContentSelector = lazy(() => import('../components/templates/FeaturedContentSelector'));
export const TemplateExportModal = lazy(() => import('../components/templates/TemplateExportModal'));

export const Cereal = lazy(() => import('../components/cereal/Cereal'));
export const SegunosCereal = lazy(() => import('../components/cereal/SegunosCereal'));
export const SegunosCerealGame = lazy(() => import('../components/cereal/SegunosCerealGame'));

export const BlogPostAnnouncementTemplateEditor = lazy(() => import('../components/blogs/BlogPostAnnouncementTemplateEditor'));
export const Changelog = lazy(() => import('../components/changelog/Changelog'));
export const Dashboard = lazy(() => import('../components/dashboard/Dashboard'));
export const EmbeddedEditor = lazy(() => import('../components/EmbeddedEditor'));
export const PopupsDashboard = lazy(() => import('../components/popups/PopupsDashboard'));
export const ReviewsDashboard = lazy(() => import('../components/reviews/ReviewsDashboard'));
export const SuspendedAccount = lazy(() => import('../components/SuspendedAccount'));
export const Welcome = lazy(() => import('../components/welcome/Welcome'));
