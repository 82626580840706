import { createContext, useContext, useState, type ReactNode } from 'react';

const EmbeddedMarketingGetterContext = createContext<boolean | undefined>(undefined);
const EmbeddedMarketingSetterContext = createContext<((embedded: boolean) => void) | undefined>(undefined);

export function useIsEmbedded() {
	const embedded = useContext(EmbeddedMarketingGetterContext);
	return Boolean(embedded);
}

export function useSetEmbedded() {
	const setEmbedded = useContext(EmbeddedMarketingSetterContext);
	return setEmbedded;
}

export type EmbeddedMarketingProviderProps = {
	children: ReactNode;
};

export default function EmbeddedMarketingProvider({ children }: EmbeddedMarketingProviderProps) {
	const [isEmbedded, setIsEmbedded] = useState(false);

	return (
		<EmbeddedMarketingGetterContext.Provider value={isEmbedded}>
			<EmbeddedMarketingSetterContext.Provider value={setIsEmbedded}>{children}</EmbeddedMarketingSetterContext.Provider>
		</EmbeddedMarketingGetterContext.Provider>
	);
}
