import { LoadingScreen } from '@segunosoftware/equinox';
import { Navigate, useLocation } from 'react-router-dom';
import { useAccountFetch } from '../hooks/useAccount';
import { FeatureFlags } from '../utils/feature-flags';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const { account } = useAccountFetch(true);
	const location = useLocation();

	if (!account) {
		return <LoadingScreen />;
	}

	const isSuspendedPath = location.pathname.indexOf('/suspended') === 0;
	const isWelcomePath = location.pathname.indexOf('/welcome') === 0;

	if (account.suspended && !isSuspendedPath) {
		return <Navigate to="/suspended" />;
	} else if (!account.suspended && isSuspendedPath) {
		return <Navigate to="/" />;
	} else if (!account.accountSurvey && !isWelcomePath && FeatureFlags.WELCOME_SURVEY_ENABLED) {
		const queryParams = new URLSearchParams(location.search);
		queryParams.append('redirect', location.pathname);
		return <Navigate to={`/welcome?${queryParams.toString()}`} />;
	}

	return element;
}
