import { LoadingScreen, useQueryString } from '@segunosoftware/equinox';
import { Loading, NavigationMenu, Provider } from '@shopify/app-bridge-react';
import { isShopifyMobile } from '@shopify/app-bridge/utilities';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { useIsFetching } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import CustomLinkComponent from './CustomLinkComponent';
import GA4UserProperties from './components/GA4UserProperties';
import ProtectedRoute from './components/ProtectedRoute';
import ScreenCapture from './components/ScreenCapture';
import SentryWrapper from './components/SentryWrapper';
import SupportChat from './components/SupportChat';
import { useHostParam } from './hooks/useHostParam';
import {
	ArchivedTemplateList,
	AutomationTriggerFlowActionEditor,
	AutomationsList,
	BackInStockRegistrants,
	BlogPostAnnouncementTemplateEditor,
	CampaignActivities,
	CampaignDetails,
	CampaignEditor,
	CampaignGroupDetails,
	CampaignList,
	CampaignTemplateEditor,
	Cereal,
	Changelog,
	ConnectionDetails,
	ConnectionsOverview,
	CreateCampaign,
	CreateCampaignGroup,
	CreateProgramOfType,
	CustomerDetails,
	CustomerSearch,
	Dashboard,
	EmbeddedEditor,
	EngagementReport,
	MailchimpConnect,
	MailchimpFinishConnection,
	OAuth,
	PopupsDashboard,
	ProgramActivities,
	ProgramDetails,
	ProgramEditor,
	ProgramMessageActivities,
	ReviewsDashboard,
	Settings,
	StoreCampaignGroupDetails,
	SubscribersReport,
	SuspendedAccount,
	TemplateList,
	TemplateStore,
	TranslationSettings,
	Welcome
} from './utils/lazy-load';

const SHOPIFY_APP_KEY = import.meta.env.VITE_SHOPIFY_KEY;

const NAVIGATION_LINKS = [
	{ label: 'Newsletters', destination: '/newsletters' },
	{ label: 'Automations', destination: '/automations' },
	{ label: 'Reports', destination: '/reports' },
	{ label: 'Templates', destination: '/templates' },
	{ label: 'Popups & Forms', destination: '/popups' },
	{ label: 'Product Reviews', destination: '/reviews' },
	{ label: 'Settings', destination: '/settings' }
];

export default function App() {
	const location = useLocation();
	const host = useHostParam() ?? '';
	// Temporarily turned off to "improve" LCP :)
	// const router = useAppBridgeRouter();
	const isOAuthPath = location.pathname.startsWith('/oauth/shopify');
	const config = useMemo(
		() => ({
			apiKey: SHOPIFY_APP_KEY,
			host,
			forceRedirect: !isOAuthPath
		}),
		[host, isOAuthPath]
	);
	return (
		<AppProvider key={isOAuthPath.toString()} i18n={translations} linkComponent={CustomLinkComponent}>
			<Provider config={config}>
				<NavigationMenu navigationLinks={NAVIGATION_LINKS} />
				<SentryWrapper>
					<Suspense fallback={<LoadingScreen delay={500} />}>
						<Routes>
							<Route path="/oauth/mailchimp/connect" element={<ProtectedRoute element={<MailchimpConnect />} />} />
							<Route path="/oauth/*" element={<OAuth />} />
							<Route path="/external/*" element={<ExternalLinks />} />
							<Route path="/*" element={<ProtectedRoute element={<AppEmbed />} />} />
						</Routes>
					</Suspense>
				</SentryWrapper>
			</Provider>
		</AppProvider>
	);
}

function AppEmbed() {
	const isFetching = useIsFetching();
	const calculatedLoading = !isShopifyMobile() && isFetching > 0;
	return (
		<>
			<Suspense fallback={<LoadingScreen delay={500} />}>
				<Routes>
					<Route path="marketing_activity_editor" element={<EmbeddedEditor />} />
					<Route path="automation_trigger_flow_action_editor" element={<AutomationTriggerFlowActionEditor />} />
					<Route path="changelog" element={<Changelog />} />
					<Route path="campaigns/*">
						<Route path="new" element={<CreateCampaignGroup />} />
						<Route path=":id" element={<CampaignGroupDetails />} />
						<Route path="" element={<Navigate to="/newsletters" />} />
					</Route>
					<Route path="newsletters/*">
						<Route path="new" element={<CreateCampaign />} />
						<Route path="template/editor/:id" element={<CampaignTemplateEditor />} />
						<Route path="editor/:id" element={<CampaignEditor />} />
						<Route path=":id/activity/:type" element={<CampaignActivities />} />
						<Route path=":id/activity" element={<CampaignActivities />} />
						<Route path=":id" element={<CampaignDetails />} />
						<Route path="" element={<CampaignList />} />
					</Route>
					<Route path="automations/*">
						<Route path="editor/:id" element={<ProgramEditor />} />
						<Route path=":id/activity/:type" element={<ProgramActivities />} />
						<Route path=":id/activity" element={<ProgramActivities />} />
						<Route path=":id/emails/:messageId/activity/:type" element={<ProgramMessageActivities />} />
						<Route path=":id/emails/:messageId/activity" element={<ProgramMessageActivities />} />
						<Route path=":id" element={<ProgramDetails />} />
						<Route path="product-purchased" element={<CreateProgramOfType programType="POST_PURCHASE" />} />
						<Route path="customer-tagged" element={<CreateProgramOfType programType="TAG_TRIGGER" />} />
						<Route path="" element={<AutomationsList />} />
					</Route>
					<Route path="templates/*">
						<Route path="editor/:id" element={<CampaignTemplateEditor />} />
						<Route path="archive" element={<ArchivedTemplateList />} />
						<Route path="store/*">
							<Route path="campaigns/:id" element={<StoreCampaignGroupDetails />} />
							<Route path="" element={<TemplateStore />} />
						</Route>
						<Route path="" element={<TemplateList />} />
					</Route>
					<Route path="blog-announcement/editor/" element={<BlogPostAnnouncementTemplateEditor />} />
					<Route path="settings/mailchimp-finish" element={<MailchimpFinishConnection />} />
					<Route path="settings" element={<Settings />} />
					<Route path="translations/*">
						<Route path=":language" element={<TranslationSettings />} />
					</Route>
					<Route path="suspended" element={<SuspendedAccount />} />
					<Route path="connections/*">
						<Route path=":id" element={<ConnectionDetails />} />
						<Route path="" element={<ConnectionsOverview />} />
					</Route>
					<Route path="customers/*">
						<Route path="search" element={<CustomerSearch />} />
						<Route path=":externalId" element={<CustomerDetails />} />
						<Route path="" element={<Navigate to="/reports/subscribers" />} />
					</Route>
					<Route path="reports/*">
						<Route path="subscribers" element={<SubscribersReport />} />
						<Route path="" element={<EngagementReport />} />
					</Route>
					<Route path="cereal" element={<Cereal />} />
					<Route path="popups" element={<PopupsDashboard />} />
					<Route path="reviews" element={<ReviewsDashboard />} />
					<Route path="welcome" element={<Welcome />} />
					<Route path="back-in-stock-registrants" element={<BackInStockRegistrants />} />
					<Route path="" element={<Dashboard />} />
				</Routes>
			</Suspense>
			<SupportChat />
			<ScreenCapture />
			<GA4UserProperties />
			{calculatedLoading && <Loading />}
		</>
	);
}

function ExternalLinks() {
	const { id } = useQueryString();

	return (
		<Routes>
			<Route path="customers" element={<Navigate to={`/customers/${id}`} />} />
		</Routes>
	);
}
