import { isNotBlank, queryKey } from '@segunosoftware/equinox';
import { QueryClient, QueryObserver, hashQueryKey, type QueryKey } from '@tanstack/react-query';
import { INVALIDATION_REGISTRY } from '../hooks/query-keys';
import { Assert } from './utils';

export type InvalidationType = 'campaign' | 'emailTemplate' | 'programMessage' | 'screenshot';
export type InvalidationId = number | string;
export type InvalidationKey = `${InvalidationType}-${InvalidationId}`;

const invalidationNodesByKey = new Map<InvalidationKey, InvalidationNode>();

class Internal {
	static queryClient: QueryClient;
	static queryObserver: QueryObserver<unknown, unknown, unknown, unknown, string[]>;
}

export default class InvalidationRegistry {
	private constructor() {}

	/**
	 * Must be called once per page refresh and before any invalidations can be made; should be done immediately after the QueryClient is
	 * created.
	 */
	static init(queryClient: QueryClient) {
		Assert.isDefined(queryClient);

		Internal.queryClient = queryClient;
		invalidationNodesByKey.clear();

		const qk = queryKey(INVALIDATION_REGISTRY);
		queryClient.setQueryData(qk, invalidationNodesByKey);

		if (!Internal.queryObserver) {
			// create an observer so RQ won't automatically purge this class from the cache
			Internal.queryObserver = new QueryObserver(queryClient, { queryKey: qk });
			Internal.queryObserver.subscribe(() => {});
		}
	}

	private static makeKey(invalidationType: InvalidationType, invalidationId: InvalidationId): InvalidationKey {
		Assert.isTruthy(isNotBlank(invalidationType));
		Assert.isTruthy(typeof invalidationId === 'number' || typeof invalidationId === 'string');
		return `${invalidationType}-${invalidationId}`;
	}

	/**
	 * Gets the node with the given type and id, creating it first if necessary.
	 */
	static get(invalidationType: InvalidationType, invalidationId: InvalidationId): InvalidationNode {
		const invalidationKey = this.makeKey(invalidationType, invalidationId as InvalidationId);
		let invalidationNode = invalidationNodesByKey.get(invalidationKey);

		if (!invalidationNode) {
			invalidationNode = new InvalidationNode(invalidationKey);
			invalidationNodesByKey.set(invalidationKey, invalidationNode);
		}

		return invalidationNode;
	}

	/**
	 * Creates a node with the given type and id, if it doesn't already exist. Adds the given keys to the node. If the node is ever
	 * invalidated, all keys will be invalidated in RQ.
	 */
	static register(invalidationType: InvalidationType, invalidationId: InvalidationId, ...queryKeys: QueryKey[]): InvalidationNode {
		const invalidationNode = this.get(invalidationType, invalidationId);

		if (queryKeys.length > 0) {
			invalidationNode.addQueryKeys(...queryKeys);
		}

		return invalidationNode;
	}

	/**
	 * Useful when tests fail, which they do, frequently :/
	 */
	static logToConsole() {
		console.dir(invalidationNodesByKey, { depth: null });
	}
}

export class InvalidationNode {
	// If these properties are private, they won't show up in the browser in the RQ dev UI.
	// No one else should modify these, though!

	invalidationKey: InvalidationKey;
	observers = new Set<InvalidationKey>();
	queryKeys = new Map<string, QueryKey>();

	constructor(invalidationKey: InvalidationKey) {
		Assert.isTruthy(isNotBlank(invalidationKey));
		this.invalidationKey = invalidationKey;
	}

	/**
	 * Adds a query key to this node. If this node is invalidated, all keys will be invalidated in RQ.
	 */
	addQueryKey(queryKey: QueryKey): this {
		Assert.isNonEmptyArray(queryKey);
		this.queryKeys.set(hashQueryKey(queryKey), queryKey);
		return this;
	}

	/**
	 * Adds the query keys to this node. If this node is invalidated, all keys will be invalidated in RQ.
	 */
	addQueryKeys(...queryKeys: QueryKey[]): this {
		Assert.isNonEmptyArray(queryKeys);
		queryKeys.forEach(queryKey => this.addQueryKey(queryKey));
		return this;
	}

	/**
	 * Adds an observer to this node. If this node is ever invalidated, the observer will also be invalidated.
	 */
	addObserver(invalidationNode: InvalidationNode): this;
	addObserver(invalidationNode: InvalidationNode, bidirectional: boolean): this;
	addObserver(invalidationType: InvalidationType, invalidationId: InvalidationId): this;
	addObserver(invalidationType: InvalidationType, invalidationId: InvalidationId, bidirectional: boolean): this;
	addObserver(arg1: InvalidationType | InvalidationNode, arg2?: InvalidationId | boolean, arg3?: boolean): this {
		if (typeof arg1 === 'string') {
			InvalidationRegistry.get(arg1, arg2 as InvalidationId).observe(this, Boolean(arg3));
		} else {
			arg1.observe(this, Boolean(arg2));
		}

		return this;
	}

	/**
	 * Observes the given node. If that node is ever invalidated, this node will be invalidated as well
	 */
	observe(invalidationNode: InvalidationNode): this;
	observe(invalidationNode: InvalidationNode, bidirectional: boolean): this;
	observe(invalidationType: InvalidationType, invalidationId: InvalidationId): this;
	observe(invalidationType: InvalidationType, invalidationId: InvalidationId, bidirectional: boolean): this;
	observe(arg1: InvalidationType | InvalidationNode, arg2?: InvalidationId | boolean, arg3?: boolean): this {
		let invalidationNode: InvalidationNode | undefined;
		let bidirectional: boolean;

		if (typeof arg1 === 'string') {
			invalidationNode = InvalidationRegistry.get(arg1, arg2 as InvalidationId);
			bidirectional = Boolean(arg3);
		} else {
			invalidationNode = arg1;
			bidirectional = Boolean(arg2);
		}

		Assert.isDefined(invalidationNode);
		Assert.isTruthy(invalidationNodesByKey.has(invalidationNode.invalidationKey));
		Assert.isFalse(this.invalidationKey === invalidationNode.invalidationKey);

		invalidationNode.observers.add(this.invalidationKey);

		if (bidirectional) {
			this.observers.add(invalidationNode.invalidationKey);
		}

		return this;
	}

	/**
	 * Invalidates this node; all of the query keys associated with this node will be invalidated in RQ.
	 * @param invalidateObservers if true, all observers will also be invalidated (and their observers, and so on).
	 */
	invalidate(invalidateObservers = true): this {
		this.queryKeys.forEach(queryKey => Internal.queryClient.invalidateQueries({ queryKey, exact: true }));

		if (invalidateObservers) {
			this.invalidateObservers();
		}

		return this;
	}

	/**
	 * Invalidates all nodes which observe this node, if any. The query keys in this node will _not_ be invalidated in RQ, but those of
	 * all observers _will_ (and their observers, and so on).
	 */
	invalidateObservers(): this {
		const invalidateObservers = (invalidationKey: InvalidationKey) => {
			const invalidationNode = invalidationNodesByKey.get(invalidationKey);

			if (!invalidationNode) {
				return; // nothing to do
			}

			if (!visitedKeys.has(invalidationKey)) {
				invalidationNode.invalidate(false);
				visitedKeys.add(invalidationKey);
			}

			invalidationNode.observers.forEach(key => {
				if (!visitedKeys.has(key)) {
					invalidateObservers(key);
				}
			});
		};

		const visitedKeys = new Set<string>([this.invalidationKey]);
		invalidateObservers(this.invalidationKey);

		return this;
	}
}
